.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.deckUnselected {
  padding: 15px 32px;
  border: 2px solid rgb(90, 90, 161);
  margin:  5px;
  border-radius: 12px;
}

.deckSelected {
  background-color: rgb(90, 90, 161);
  padding: 15px 32px;
  margin:  5px;
  border: 2px solid rgb(90, 90, 161);
  border-radius: 12px;
}


.BackButton {
  /*background-color: #e7e7e7; */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid black;
  margin:  5px;
  border-radius: 12px;
  /*font-size: 16px;*/
}

.PrevCardButton, .NextCardButton {
  border: none;
  color: white;
  padding: 15px 32px;
  margin:  5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid black;
  border-radius: 12px;
}

.NextButton {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 2px solid black;
  margin:  5px;
  border-radius: 12px;
}

.CardContent {
  padding-top: 30px;
  padding-bottom: 30px;
  margin:  5px;
  font-size: 35px;
}

.CardCount {
  margin-top:  20px;
}